@import 'variables';
@import 'mixins';

.social-links-list{
    @include clear-list;
    &__item{
        float:left;
        margin-right:16px;
        margin-bottom:16px;
    }
}
@import 'variables';
@import 'mixins';


.header p{
    margin:0;
    padding:0;
}

.out{
    outline:1px solid red;
}

.row-flex {
    display: flex;
    flex-flow: row wrap;
}

@media (max-width: 767px) {
    .text-xs-center {
        text-align: center;
    }     
  }

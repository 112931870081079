@import 'variables';
@import 'mixins';

$header-height:0px;
.header{
    border:none;

    &__section{
        padding:10px 0;
    }

    &__picture{ 
        background-image: url(../images/dslim_top.jpg);
        background-repeat: no-repeat;
    }

    &__logo{
       display:inline-block; 
       margin-bottom:1rem;
    }

    &__contacts{
        font-size: 18px;
    }
    
    

}
@mixin clear{
    margin: 0;
    padding: 0;
}

@mixin clear-list{
    @include clear;
    list-style: none;   
}

@mixin flex-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@mixin nav-link {
    font-weight: bold;
    color: color(text);
    &:hover{
        text-decoration:none;
        color:color(text);
    }
}
@mixin hover-trans($color){
    &:hover{
        text-decoration:none;
        color:$color;
        transition:0.3s color;
    }
}

@mixin text-italic{
    font-family: 'EB Garamond', serif;
    font-style:italic;
}

@mixin text-thin{
    font-family: 'Geometria', sans-serif;
    font-weight: 300;
}